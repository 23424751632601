import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import CustomScrollbars from 'util/CustomScrollbars';
import { protectSupportUserRoute, protectAansluitteamUserRoute } from '../../services/authService';

const closest = (el, selector) => {
  try {
    let matchesFn;
    // find vendor prefix
    [
      'matches',
      'webkitMatchesSelector',
      'mozMatchesSelector',
      'msMatchesSelector',
      'oMatchesSelector'
    ].some(fn => {
      if (typeof document.body[fn] === 'function') {
        matchesFn = fn;
        return true;
      }
      return false;
    });

    let parent;

    // traverse parents
    while (el) {
      parent = el.parentElement;
      if (parent && parent[matchesFn](selector)) {
        return parent;
      }
      // eslint-disable-next-line no-param-reassign
      el = parent;
    }
  } catch (e) {
    console.log(e);
  }

  return null;
};

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        const parentLiEle = closest(this, 'li');
        if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = closest(this, 'li');
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains('open'))
            ) {
              menuLi[j].classList.remove('open');
            } else {
              // eslint-disable-next-line no-unused-expressions
              menuLi[j].classList.contains('open')
                ? menuLi[j].classList.remove('open')
                : menuLi[j].classList.add('open');
            }
          }
        }
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    if (!activeLi) return;
    try {
      const activeNav = closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        closest(activeNav, 'li').classList.add('open');
      } else {
        closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate() {
    const { history } = this.props;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      if (menuLi[i].classList.contains('menu')) {
        menuLi[i].classList.remove('open', 'active');
      }
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`);
    if (!activeLi) return;
    try {
      const activeNav = closest(activeLi, 'ul');
      if (activeNav.classList.contains('sub-menu')) {
        closest(activeNav, 'li').classList.add('open');
      } else {
        closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { authUser } = this.props;
    return (
      <CustomScrollbars className="scrollbar">
        {
        <ul className="nav-menu">
          <li className="menu no-arrow">
            <NavLink to="/app/dashboard">
              <i className="zmdi zmdi-chart-donut zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li> 
          {protectAansluitteamUserRoute(authUser.groups) ?
            <li className="menu no-arrow">
              <NavLink to="/app/my-actions">
                <i className="zmdi zmdi-assignment-check zmdi-hc-fw" />
                <span className="nav-text">Mijn Taken</span>
              </NavLink>
            </li> : null
          }
          <li className="menu no-arrow">
            <NavLink to="/app/search">
              <i className="zmdi zmdi-search zmdi-hc-fw" />
              <span className="nav-text">Zoeken</span>
            </NavLink>
          </li>
          { protectAansluitteamUserRoute(authUser.groups) ?
            <>
              <li className="menu no-arrow">
                <NavLink to="/app/calender-dagen">
                  <i className="zmdi zmdi-calendar zmdi-hc-fw" />
                  <span className="nav-text">Kalender (De)Blokkeren</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/export-planning">
                  <i className="zmdi zmdi-file-text zmdi-hc-fw" />
                  <span className="nav-text">Export Planning Data</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/export-htg-planning">
                  <i className="zmdi zmdi-file-text zmdi-hc-fw" />
                  <span className="nav-text">Export Portal Data</span>
                </NavLink>
              </li>
            </> : null
          }
          { protectSupportUserRoute(authUser.groups) ? 
            <>
              <li className="menu no-arrow">
                <NavLink to="/app/import-organisation">
                  <i className="zmdi zmdi-upload zmdi-hc-fw" />
                  <span className="nav-text">Import Organisaties</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/import-grootverbruikers">
                  <i className="zmdi zmdi-upload zmdi-hc-fw" />
                  <span className="nav-text">Import Grootverbruikers</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/send-email">
                  <i className="zmdi zmdi-file-text zmdi-hc-fw" />
                  <span className="nav-text">Verstuur mailing</span>
                </NavLink>
              </li>
            </> : null
          }
        </ul>
        }
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    authUser
  };
};

export default withRouter(connect(mapStateToProps)(SidenavContent));
