import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import { protectAansluitteamUserRoute, protectSupportUserRoute } from '../services/authService';

class App extends React.Component {
  render() {
    const { match, drawerType, navigationStyle, authUser } = this.props;

    /* eslint-disable no-nested-ternary, indent */
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'collapsible-drawer'
      : 'mini-drawer';
    /* eslint-enable no-nested-ternary, indent */

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? 'app-header-horizontal'
                : ''
            }`}
          >
            <Header />
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route
                  path={`${match.url}/dashboard`}
                  component={asyncComponent(() =>
                    import('./routes/DashboardPage')
                  )}
                />
                {protectAansluitteamUserRoute(authUser.groups) ? <Route
                  path={`${match.url}/my-actions`}
                  component={asyncComponent(() =>
                    import('./routes/MyActionsPage')
                  )}
                /> : null}
                <Route
                  path={`${match.url}/search`}
                  component={asyncComponent(() =>
                    import('./routes/SearchPage')
                  )}
                />
                <Route
                  path={`${match.url}/process/:processInstanceId`}
                  component={asyncComponent(() =>
                    import('./routes/ProcessDetailsPage')
                  )}
                />
                {protectAansluitteamUserRoute(authUser.groups) ? <Route
                  path={`${match.url}/export-planning`}
                  component={asyncComponent(() => 
                    import('./routes/ExportPlanningPage')
                  )}
                /> : null}
                 {protectAansluitteamUserRoute(authUser.groups) ? <Route
                  path={`${match.url}/calender-dagen`}
                  component={asyncComponent(() => 
                    import('./routes/CalenderDaysPage')
                  )}
                /> : null}
                {protectAansluitteamUserRoute(authUser.groups) ? <Route
                  path={`${match.url}/export-htg-planning`}
                  component={asyncComponent(() =>
                    import('./routes/ExportHTGPlanningPage')
                  )}
                /> : null}
                {protectSupportUserRoute(authUser.groups) ? <Route
                  path={`${match.url}/import-organisation`}
                  component={asyncComponent(() =>
                    import('./routes/ImportOrganisationPage')
                  )}
                /> : null}
                {protectSupportUserRoute(authUser.groups) ? <Route
                  path={`${match.url}/import-grootverbruikers`}
                  component={asyncComponent(() =>
                    import('./routes/ImportGrootverbruikersPage')
                  )}
                /> : null}
                {protectSupportUserRoute(authUser.groups) ? <Route
                  path={`${match.url}/send-email`}
                  component={asyncComponent(() =>
                    import('./routes/SendEmailPage')
                  )}
                /> : null}
              </Switch>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { authUser } = auth;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    authUser
  };
};
export default withRouter(connect(mapStateToProps)(App));
