import {
  GET_PROCESSES_BY_KEY_AND_TENANT,
  GET_PROCESSES_BY_KEY_AND_TENANT_SUCCESS,
  GET_PROCESS_DETAILS,
  GET_PROCESS_DETAILS_SUCCESS,
  GET_PROCESSES_TABLE_DATA,
  GET_PROCESSES_TABLE_DATA_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  list: null,
  details: null,
  table: {
    columns: [
      {
        id: 'EORInummer',
        align: 'left',
        label: 'EORI nummer'
      },
      {
        id: 'organisatieNaam',
        align: 'left',
        label: 'Organisatie naam'
      },
      {
        id: 'volgnummerBestaandeAGS',
        align: 'left',
        label: 'Volg nummer'
      },
      {
        id: 'naamSoftwareLeverancier',
        align: 'left',
        label: 'Leverancier'
      }
    ],
    data: [],
    order: '',
    orderBy: '',
    page: 0,
    rowsPerPage: 25,
    totalCount: 0,
    searchQuery: '',
    loading: false
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROCESSES_BY_KEY_AND_TENANT: {
      return {
        ...state,
        list: null
      };
    }

    case GET_PROCESSES_BY_KEY_AND_TENANT_SUCCESS: {
      return {
        ...state,
        list: action.payload
      };
    }

    case GET_PROCESS_DETAILS: {
      return {
        ...state,
        details: null
      };
    }

    case GET_PROCESS_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.payload
      };
    }

    case GET_PROCESSES_TABLE_DATA: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: true
        }
      };
    }

    case GET_PROCESSES_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
          loading: false
        }
      };
    }

    default:
      return state;
  }
};
