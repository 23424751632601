export const protectRoute = (userGroups) => {
  let blockRouteFlag = false;

    for (let group of userGroups) {
      if (group.name === "BelastingdienstExtern") {
        blockRouteFlag = true;
      }
    }

    return blockRouteFlag;
}

export const protectAansluitteamUserRoute = (userGroups) => {
  let blockAansluitteamRoute = false;

  for (let group of userGroups) {
    if (group.name === 'BelastingdienstAansluitteam') {
      blockAansluitteamRoute = true;
    }
  }

  return blockAansluitteamRoute;
}

export const protectSupportUserRoute = (userGroups) => {
  let blockSupportRoute = false;

  for (let group of userGroups) {
    if (group.name === 'BelastingdienstSupport') {
      blockSupportRoute = true;
    }
  }

  return blockSupportRoute;
}