import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_DASHBOARD_STATUSES } from 'constants/ActionTypes';
import { getDashboardStatusesSuccess } from 'actions';
import { showMessage } from 'actions/NotificationMessage';
import { getProcessesCount, getHistoryProcessesCount, isHistoryProcess } from './Processes';
import { getStatuses } from '../services/statusService';

function* getProcessesCountForStatus(statusName, callToPerform) {
  const response = yield callToPerform;
  return {
    statusName,
    count: response.count
  };
}

function* getDashboardStatusInfo() {
  try {
    const statusesMap = getStatuses();
    const calls = [];
    const statusCounts = {};

    // Separate call to workflow for each status
    for (let [statusName, status] of statusesMap.entries()) {
      calls.push(
        getProcessesCountForStatus(
          statusName,
          call(isHistoryProcess(statusName) ? getHistoryProcessesCount : getProcessesCount, {
            processDefinitionKey: status.definitionKey,
            tenantIdIn: ['Belastingdienst'],
            variables: [{ name: 'status', operator: 'eq', value: statusName }]
          })
        )
      );
    }

    // Make an object with statusName as key and count as value
    const statusCountsResults = yield all(calls);
    for (let statusCountResult of statusCountsResults) {
      statusCounts[statusCountResult.statusName] = statusCountResult.count;
    }

    yield put(getDashboardStatusesSuccess(statusCounts));
  } catch (error) {
    yield put(
      showMessage('Something is wrong with server. Please try again later.')
    );
  }
}

export function* getDashboardStatusesSaga() {
  yield takeEvery(GET_DASHBOARD_STATUSES, getDashboardStatusInfo);
}

export default function* rootSaga() {
  yield all([fork(getDashboardStatusesSaga)]);
}
