// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

// Message & loader
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const ON_HIDE_LOADER = 'hide_loader';
export const ON_SHOW_LOADER = 'show_loader';

// Auth const
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';

// Dashboard
export const GET_DASHBOARD_STATUSES = 'GET_DASHBOARD_STATUSES';
export const GET_DASHBOARD_STATUSES_SUCCESS = 'GET_DASHBOARD_STATUSES_SUCCESS';

// Tasks
export const GET_TASKS_BY_GROUP_NAME = 'GET_TASKS_BY_GROUP_NAME';
export const GET_TASKS_BY_GROUP_NAME_SUCCESS =
  'GET_TASKS_BY_GROUP_NAME_SUCCESS';
export const GET_TASKS_BY_GROUPS_CANDIDATE = 'GET_TASKS_BY_GROUPS_CANDIDATE';
export const GET_TASKS_BY_GROUPS_CANDIDATE_SUCCESS = 'GET_TASKS_BY_GROUPS_CANDIDATE_SUCCESS';

// Processes
export const GET_PROCESSES_BY_KEY_AND_TENANT =
  'GET_PROCESSES_BY_KEY_AND_TENANT';
export const GET_PROCESSES_WITH_VARIABLES_BY_KEY_AND_TENANT =
  'GET_PROCESSES_WITH_VARIABLES_BY_KEY_AND_TENANT';
export const GET_PROCESSES_BY_KEY_AND_TENANT_SUCCESS =
  'GET_PROCESSES_BY_KEY_AND_TENANT_SUCCESS';
export const GET_PROCESS_DETAILS = 'GET_PROCESS_DETAILS';
export const GET_PROCESS_DETAILS_SUCCESS = 'GET_PROCESS_DETAILS_SUCCESS';
export const GET_PROCESSES_TABLE_DATA = 'GET_PROCESSES_TABLE_DATA';
export const GET_PROCESSES_TABLE_DATA_SUCCESS = 'GET_PROCESSES_TABLE_DATA_SUCCESS';
