const statuses = new Map();

statuses.set('Intake', {
  definitionKey: 'IntakeDeelnemer',
  label: 'Intake',
  chartColor: '#ff851b'
});

statuses.set('Planning', {
  definitionKey: 'PlanningDeelnemer',
  label: 'Planning',
  chartColor: '#85144b'
});

statuses.set('Functioneel aansluiten op Productie', {
  definitionKey: 'StartFunctioneleAansluitingProductie',
  label: 'Functioneel aansluiten op Productie',
  chartColor: '#3d9970'
});

statuses.set('Gemigreerd', {
  definitionKey: 'Klantenreis',
  label: 'Gemigreerd',
  chartColor: '#2ecc40'
});

statuses.set('Not Migrating', {
  definitionKey: 'Klantenreis',
  label: 'Migreert niet',
  chartColor: '#FF0000'
});

export const getProcessDefKeyByStatus = statusName => {
  const foundStatus = statuses.get(statusName);
  if (!foundStatus) {
    return null;
  }

  return foundStatus.definitionKey;
};

export const getLabelByStatus = statusName => {
  const foundStatus = statuses.get(statusName);
  if (!foundStatus) {
    return null;
  }

  return foundStatus.label;
};

export const getStatuses = () => {
  return new Map(statuses);
};
