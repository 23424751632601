import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { SIGNIN_USER } from 'constants/ActionTypes';
import {
  userSignInSuccess,
  showAuthLoader,
  hideAuthLoader,
  postAPI,
  getAPI
} from 'actions';
import { showMessage } from 'actions/NotificationMessage';

const signInUserWithEmailPasswordRequest = async (username, password, token) => {
  const resp = await postAPI(
    'identity/verify',
    {
      username,
      password
    },
    token
  );
  return resp;
};

const getTenants = async (username, token) => {
  const resp = await getAPI('tenant', { userMember: username }, token);
  return resp;
};

const getGroups = async (username, token) => {
  const resp = await getAPI('group', { member: username }, token);
  return resp;
};

function* signInUserWithEmailPassword({ payload }) {
  const { username, password } = payload;
  try {
    yield put(showAuthLoader());
    const token = Buffer.from(`${username}:${password}`).toString('base64');
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      username,
      password,
      token
    );
    if (!signInUser.authenticated) {
      yield put(showMessage('Incorrect username or password'));
    } else {
      const tenants = yield call(getTenants, username, token);
      if (!tenants) {
        throw new Error('no tenants');
      }
      let tenantFound = false;
      for (let tenant of tenants) {
        if (tenant.id === "Belastingdienst") {
          tenantFound = true;
          break;
        }
      }
      if (!tenantFound) {
        yield put(showMessage('User does not belong to required tenant'));
      } else {
        const groups = yield call(getGroups, username, token);
        signInUser.tenants = tenants;
        signInUser.groups = groups;
        signInUser.token = token;
        yield put(userSignInSuccess(signInUser));
      }
    }
    yield put(hideAuthLoader());
  } catch (error) {
    yield put(
      showMessage('Something is wrong with server. Please try again later.')
    );
    yield put(hideAuthLoader());
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export default function* rootSaga() {
  yield all([fork(signInUser)]);
}
