import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

import {
  GET_PROCESSES_BY_KEY_AND_TENANT,
  GET_PROCESS_DETAILS,
  GET_PROCESSES_TABLE_DATA,
  GET_PROCESSES_WITH_VARIABLES_BY_KEY_AND_TENANT
} from 'constants/ActionTypes';
import {
  getProcessesByKeyAndTenantSuccess,
  getProcessDetailsSuccess,
  getProcessesTableDataSuccess,
  postAPI,
  getAPI
} from 'actions';
import { showMessage } from 'actions/NotificationMessage';
import { getProcessDefKeyByStatus } from '../services/statusService';

export function isHistoryProcess(statusName) {
  if (statusName === 'Gemigreerd' || statusName === 'Not Migrating') {
    return true;
  }
  return false;
}

function getProcessesVariables(variablesResponse) {
  const processesVariables = variablesResponse.map(elem => {
    let allVariables = {};
    if (Array.isArray(elem.variables)) {
      elem.variables.forEach(variable => {
        allVariables[variable.name] = {
          type: variable.type,
          value: variable.value,
          valueInfo: variable.valueInfo
        };
      });
    } else {
      allVariables = elem.variables;
    }

    return {
      processInstanceId: elem.processInstanceId,
      variables: allVariables
    };
  });

  return processesVariables;
}

function convertVarsArrayToObj(variables) {
  if (Array.isArray(variables)) {
    const allVars = {};
    variables.forEach(variable => {
      allVars[variable.name] = {
        type: variable.type,
        value: variable.value,
        valueInfo: variable.valueInfo
      };
    });
    return allVars;
  }

  return variables;
}

const getProcessesRequest = async query => {
  const resp = await postAPI('process-instance', query);
  return resp;
};

const getHistoryProcesses = async query => {
  const resp = await postAPI(`history/process-instance`, query);
  return resp;
};

export const getProcessesCount = async query => {
  const resp = await postAPI('process-instance/count', query);
  return resp;
};

export const getHistoryProcessesCount = async query => {
  const resp = await postAPI(`history/process-instance/count`, query);
  return resp;
};

const getProcessVariablesRequest = async processInstanceId => {
  const resp = await getAPI(`process-instance/${processInstanceId}/variables`);
  return resp;
};

const getVariableInstance = async processInstanceId => {
  const resp = await getAPI(`variable-instance?processInstanceIdIn=${processInstanceId}`);
  return resp;
};

const getHistoryVariableInstance = async processInstanceId => {
  const resp = await getAPI(`history/variable-instance?processInstanceId=${processInstanceId}`);
  return resp;
};

const getActivities = async processInstanceId => {
  const resp = await getAPI(`process-instance/${processInstanceId}/activity-instances`);
  return resp;
};

const getXML = async processDefinitionKey => {
  const resp = await getAPI(`process-definition/key/${processDefinitionKey}/tenant-id/Belastingdienst/xml`);
  return resp;
};

function* getProcesses({ payload }) {
  try {
    const processes = yield call(getHistoryProcesses, payload);
    yield put(getProcessesByKeyAndTenantSuccess(processes));
  } catch (error) {
    yield put(showMessage('Something is wrong with server. Please try again later.'));
  }
}

function* getProcessDetails({ payload: processInstanceId }) {
  try {
    const mainProcessVars = convertVarsArrayToObj(yield call(getHistoryVariableInstance, processInstanceId));
    const status = mainProcessVars.status.value;
    const businessKey = mainProcessVars.EORInummer.value + '-' + mainProcessVars.volgnummerBestaandeAGS.value;
    const processDefinitionKey = getProcessDefKeyByStatus(status);

    let activities = {},
      xml,
      subProcessId,
      details;
    if (processDefinitionKey && processDefinitionKey !== '-') {
      const query = {
        processDefinitionKey,
        tenantIdIn: ['Belastingdienst']
      };
      if (isHistoryProcess(status)) {
        query.processInstanceBusinessKey = businessKey;
      } else {
        query.businessKey = businessKey;
      }
      const processes = yield call(isHistoryProcess(status) ? getHistoryProcesses : getProcessesRequest, query);
      const process = processes[0];
      subProcessId = process.id;
      details = convertVarsArrayToObj(
        yield call(isHistoryProcess(status) ? getHistoryVariableInstance : getProcessVariablesRequest, subProcessId)
      );

      if (!isHistoryProcess(status)) {
        activities = yield call(getActivities, subProcessId);
        xml = yield call(getXML, processDefinitionKey);
      }
    }

    yield put(
      getProcessDetailsSuccess({
        subProcessId,
        details,
        activities,
        xml: xml ? xml.bpmn20Xml.replace(/\\n/gu, '') : ''
      })
    );
  } catch (error) {
    yield put(showMessage('Something is wrong with server. Please try again later.'));
  }
}

// This function is used by getProcessesTableData in order to provide call response
// that contains processInstanceId, not only variables (this is required for mapping
// variables back into process)
function* getProcessVariablesForProcess(processInstanceId, callToPerform) {
  const response = yield callToPerform;
  return {
    processInstanceId,
    variables: response
  };
}

function* getProcessesWithVariables({ payload }) {
  try {
    const processes = yield call(getHistoryProcesses, payload);

    const callsForVariables = processes.map(process => {
      if (process.variables) {
        return {
          processInstanceId: process.id,
          variables: process.variables
        };
      }
      if (isHistoryProcess(payload.status) || process.endTime) {
        return getProcessVariablesForProcess(process.id, call(getHistoryVariableInstance, process.id));
      }
      return getProcessVariablesForProcess(process.id, call(getVariableInstance, process.id));
    });

    const variablesResponse = yield all(callsForVariables);
    const processesVariables = getProcessesVariables(variablesResponse);

    // Write variables to processes in list.
    processesVariables.forEach(processVariables => {
      const { processInstanceId, variables } = processVariables;
      const processFromList = processes.find(listProcess => listProcess.id === processInstanceId);
      processFromList.variables = variables;
    });
    
    yield put(getProcessesByKeyAndTenantSuccess(processes));
  } catch (error) {
    yield put(showMessage('Something is wrong with server. Please try again later.'));
  }
}

function* getProcessesTableData({ payload }) {
  // Get data from redux state
  const tableState = yield select(state => state.processes.table);
  const list = yield select(state => state.processes.list);

  // Marge data from argument and current table state from redux
  const tableData = { ...tableState, ...payload };

  let resultsList;

  if (tableData.searchQuery) {
    // In case of search query provided, call for every column and
    // combine results
    const searchCalls = tableData.columns.map(column => {
      const variablesToSearch = [
        {
          name: column.id,
          operator: 'like',
          value: `%${tableData.searchQuery}%`
        }
      ];

      // Include status filter into search if required
      if (payload.status) {
        variablesToSearch.push({
          name: 'status',
          operator: 'eq',
          value: payload.status
        });
      }

      return call(getHistoryProcesses, {
        processDefinitionKey: 'Klantenreis',
        tenantIdIn: ['Belastingdienst'],
        variables: variablesToSearch,
        variableValuesIgnoreCase: true
      });
    });
    const searchResults = yield all(searchCalls);
    const searchResultsFlat = searchResults.flat();

    // Get only distinct processes (for the case when search result contains
    // same process multiple times)
    resultsList =
      // making set of unique process IDs
      Array.from(new Set(searchResultsFlat.map(p => p.id)))
        // loop on unique IDs and form array of processes
        .map(id => {
          const process = searchResultsFlat.find(p => p.id === id);
          return process;
        });
  } else if (payload.status) {
    const searchResults = yield call(isHistoryProcess(payload.status) ? getHistoryProcesses : getProcessesRequest, {
      processDefinitionKey: 'Klantenreis',
      tenantIdIn: ['Belastingdienst'],
      variables: [
        {
          name: 'status',
          operator: 'eq',
          value: payload.status
        }
      ]
    });
    const searchResultsFlat = searchResults.flat();
    resultsList = searchResultsFlat;
  } else {
    resultsList = list;
  }

  // Calculate values for pagination
  const totalCount = resultsList.length;
  const startIndex = tableData.page * tableData.rowsPerPage;
  const endIndex = startIndex + tableData.rowsPerPage;

  // Make an array of data (for one page)
  const processesPageList = resultsList.slice(startIndex, endIndex);

  // Get variables for all processes on page.
  // Get existing variables from process without call (if exists).
  const callsForVariables = processesPageList.map(process => {
    if (process.variables) {
      return {
        processInstanceId: process.id,
        variables: process.variables
      };
    }
    if (isHistoryProcess(payload.status) || process.endTime) {
      return getProcessVariablesForProcess(process.id, call(getHistoryVariableInstance, process.id));
    }
    return getProcessVariablesForProcess(process.id, call(getVariableInstance, process.id));
  });

  const variablesResponse = yield all(callsForVariables);
  const processesVariables = getProcessesVariables(variablesResponse);

  // Loop through current table-page processes variabels and make table data rows.
  // Additionaly, write variables to processes in list for future reuse.
  const dataToDisplay = processesVariables.map(processVariables => {
    const { processInstanceId, variables } = processVariables;
    const processFromList = list.find(listProcess => listProcess.id === processInstanceId);
    processFromList.variables = variables;

    // Make table row from process variables
    const row = tableData.columns.map(column => variables[column.id].value);
    return {
      id: processInstanceId,
      row
    };
  });

  yield put(
    getProcessesTableDataSuccess({
      ...tableData,
      data: dataToDisplay,
      totalCount
    })
  );
  yield put(getProcessesByKeyAndTenantSuccess(list));
}

export function* getProcessesSaga() {
  yield takeEvery(GET_PROCESSES_BY_KEY_AND_TENANT, getProcesses);
  yield takeEvery(GET_PROCESSES_WITH_VARIABLES_BY_KEY_AND_TENANT, getProcessesWithVariables);
}

export function* getProcessDetailsSaga() {
  yield takeEvery(GET_PROCESS_DETAILS, getProcessDetails);
}

export function* getProcessDetailWithVariablesSaga() {
  yield takeEvery(GET_PROCESS_DETAILS, getProcessDetails);
}

export function* getProcessesTableDataSaga() {
  yield takeEvery(GET_PROCESSES_TABLE_DATA, getProcessesTableData);
}

export default function* rootSaga() {
  yield all([fork(getProcessesSaga), fork(getProcessDetailsSaga), fork(getProcessesTableDataSaga)]);
}
